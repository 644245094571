<template>
  <div class="container">
    <!-- 上部分 -->
    <div class="top">
      <img src="../assets/01.png" alt="Logo" class="logo">
    </div>
    
 
    <!-- 下部分 -->
    <div class="bottom">
      <button class="button"  @click="iosTg"><img src="../assets/03.png" width="150"  ></button>
      <button class="button" @click="downloadApp" ><img src="../assets/04.png" width="150" ></button>
      <button class="button" @click="atz"><img src="../assets/02.png"  width="150"></button>
    </div>
  </div>
</template>

 

<style scoped>

.container {
  display: flex;
  flex-direction: column;
 
  background-image: url("../assets/02.jpg");
 

  background-size: cover; /* 让背景图片充满容器 */
  background-position: center; /* 将背景图片居中 */
  height: 99vh; /* 使容器高度等于视口高度，确保背景铺满整个屏幕 */
 
 

}

.top {
  display: flex;
  align-items: center;
  padding: 20px;
}

.logo {
  width: 100px; /* 根据需要调整大小 */
  height: auto; /* 根据需要调整大小 */
}

 

.bottom {
  display: flex;
    justify-content: flex-start;
   
    flex-wrap: wrap; /* 允许换行 */
    position: fixed;
    bottom: 0; 

    display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px; 
}

.button {
  
  border: none;
  background: none;
  cursor: pointer;

}
</style>



<script>
import { ref } from 'vue'
import router from '@/router/index'
import { useRoute } from 'vue-router'
import axios from 'axios';
export default {
  name: 'indexView',
  setup() {


    const images = [
      "/011.png",
      "/022.png",
      "/033.png",
      "/044.png",
      "/055.png",
    ];

    var currentIndex = 0;

    const imgurl=ref("");

    
    const currentImage = () => {
      imgurl.value=  images[currentIndex];
    }

    currentImage();

    const   startCarousel = () => {
      setInterval(() => {
        currentIndex = (currentIndex + 1) % images.length;
        currentImage();
      }, 3000); 
    };
    startCarousel();

    const route = useRoute()

    const key = route.query.code;

    const iosTg = () => {
      router.push({ path: '/ios' })
    }

    const downloadApp = () => {
      if (key) {
        window.location.href = "/sepinhui.apk?code=" + key
      } else {
        window.location.href = "/sepinhui.apk"
      }
    }

    const  atz=()=>{
      window.location.href ="https://h5.sephxd.xyz"
    }


    const fetchData = () => {
      var url = '/visits/addVisits';
      if (key) {
        url = "/visits/addVisits?code=" + key
      }

      axios.get(url)
        .then(response => {
          // 请求成功，将数据存储在data属性中
          //this.data = response.data;
        })
        .catch(error => {
          // 请求失败，处理错误
          console.error('Error fetching data:', error);
        });
    }

    fetchData();

    return {
      iosTg,
      downloadApp,
      imgurl,
      atz,
    }
  }



}
</script>
